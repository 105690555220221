<!-- 资讯详情 -->
<template>
  <div class="do-page-information-details-pdf">
    <base-nav-bar @click-left="$router.go(-1)" left-arrow :title="info.title">
    </base-nav-bar>
    <!-- <van-loading v-if="loading" class="do-detailspdf-loading" color="#0094ff"
      >PDF 加载中...</van-loading
    > -->
    <base-bscroll
      :safeareaNavbar="true"
      @refScroll="refScroll"
      class="do-page-information-details-pdf-scroll"
      :options="{ bounce: true, firstChildrenHeight: true }"
    >
      <div class="do-page-informationdetails-pdf-content">
        <van-skeleton
          :loading="loading"
          :round="true"
          title
          class="information-detailspdf-skeleton"
          :row="20"
        >
        </van-skeleton>
        <div id="pdf-el"></div>

        <base-nodata
          v-if="isError"
          image="error"
          description="该设备无法显示，PDF"
        ></base-nodata>
        <!-- <iframe
        src="https://static-mp-c78a8ece-7ab7-46da-82af-e2b3fb9c9fc8.next.bspapp.com/f1040es.pdf"
        frameBorder="0"
        scrolling="auto"
        width="100%"
        height="100%"
      ></iframe> -->
        <!-- <div v-html="html"></div> -->
      </div>
    </base-bscroll>
  </div>
</template>
<script setup>
import { onMounted, onBeforeUnmount, onUnmounted, ref } from "vue";
import Pdfh5 from "pdfh5";
import { useRoute } from "vue-router";
import { Toast } from "vant";
import { getConsultsdetails } from "@/axios/information";
const isError = ref(false);
const loading = ref(false);
const info = ref({});
const route = useRoute();
let scrollRef = null;
let pdfh5 = null;
function refScroll(scroll) {
  scrollRef = scroll;
}

async function getDatasDetails() {
  try {
    const { code, data } = await getConsultsdetails(route.params.id);
    if (code === 0 && data) {
      info.value = data;
    }
    pdfRender();
  } catch (error) {
    if (error) {
      Toast({
        position: "bottom",
        message: error.msg || "服务端错误 ",
      });
    }
  }
}
onMounted(() => {
  getDatasDetails();
});

function pdfRender() {
  try {
    loading.value = true;
    console.log("info.value.url", info.value.url);
    pdfh5 = new Pdfh5("#pdf-el", {
      renderType: "svg",
      pdfurl: info.value.url,
    });
    //监听完成事件
    pdfh5.on("complete", function (status, msg, time) {
      console.log(
        "状态：" +
          status +
          "，信息：" +
          msg +
          "，耗时：" +
          time +
          "毫秒，总页数：" +
          this.totalNum
      );
      loading.value = false;
    });
    pdfh5.on("success", function (...info) {
      setTimeout(() => {
        if (scrollRef) scrollRef.refresh();
      }, 200);
    });
    pdfh5.on("error", function (...info) {
      console.log("error" + info);
      isError.value = true;
      if (pdfh5) pdfh5.destroy();
    });
  } catch (error) {
    loading.value = false;
    console.log("err", error);
  }
}

onUnmounted(() => {
  console.log("onUnmounted");
  if (pdfh5) pdfh5.destroy();
});
</script>

<style lang="scss">
.do-page-information-details-pdf {
  height: 100%;
  display: flex;
  flex-direction: column;
  &-scroll {
    // height: 5rem;
  }

  .information-detailspdf-skeleton {
    padding-top: 0.24rem;

    .van-skeleton__title {
      background: #e9e9e9;
    }
    .van-skeleton__row {
      background: #e9e9e9;
    }
  }

  .do-detailspdf-loading {
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .loadingBar {
    display: none !important;
  }
  .pdfjs .loadEffect {
    display: none !important;
  }
  .pdfjs {
    background: #f2f4f7;
  }
  .do-com-navbar {
    background: #0057ff;
    .van-nav-bar__arrow {
      color: #ffffff !important;
    }
    .van-nav-bar__title {
      font-size: 0.32rem;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .do-page-informationdetails-pdf-content {
    padding: 0.08rem 0rem 0.32rem 0;
    padding-bottom: 0;
    color: #666;
    flex: 1;
    .title {
      font-size: 0.32rem;
      font-weight: 500;
      padding: 0 0.32rem;
      color: #02040f;
    }
    .info {
      margin-top: 0.08rem;
      padding: 0 0.32rem;
      margin-bottom: 0.24rem;
      .split {
        margin: 0 0.16rem;
        color: rgba(2, 4, 15, 0.12);
      }
      span {
        font-size: 0.24rem;
        font-weight: 400;
        color: rgba(2, 4, 15, 0.25);
      }
    }
  }
  .do-com-scroll-wrapper {
    background: #f2f4f7;
  }
}
</style>
